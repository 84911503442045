.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

@media (min-width: 602px) {
  .content {
    width: 70%;
  }
}

@media (max-width: 601px) {
  .content {
    width: 100%;
  }
}

.content {
  flex-grow: 3;
  margin: auto;
}

.rightPanel {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
}

.linkStyle {
  text-decoration: none;
  color: var(--green-text);
}

.body {
  height: 100%;
}

.center {
  text-align: center;
}

.footer {
  text-align: center;
}

.heart-icon {
  color: var(--heart);
  font-size: 13px;
}

.blackLink {
  color: black;
}

.profileIcons {
  display: flex;
  justify-content: space-around;
}

.footer-profile-icons {
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  width: 20%;
}

.contact-profile-icons {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 60%;
  font-size: 40px;
}

.profileIcon:hover {
  color: var(--orange-text);
}

div.separator {
  background-color: var(--separator);
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.center-content {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.green {
  color: var(--green-text);
}

.button {
  appearance: button;
  backface-visibility: hidden;
  background-color: var(--green-text);
  color: var(--button-text);
  border-radius: 3px;
  border-width: 0;
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0,
    rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 100%;
  height: 35px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  font-size: medium;
  text-transform: none;
  transform: translateZ(0);
  transition:
    all 0.2s,
    box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:disabled {
  cursor: default;
}

.button:focus {
  box-shadow:
    rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0,
    rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

.flex-center {
  display: flex;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
p,
small,
ul,
li {
  color: var(--info-text);
}

div.blogpost {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: large;
  padding-left: 3px;
  padding-right: 3px;
}

.topHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 600px) {
  div.header-dark-mode {
    margin-left: auto;
  }

  .footer-profile-icons {
    width: 50%;
  }

  div.context {
    width: 100%;
  }
}

.NavBar {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-around;
  color: var(--green-text);
}

a.activeNavLink {
  text-decoration: none;
  color: var(--orange-text);
}

.header-nav-links {
  display: flex;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  justify-content: space-around;
  max-width: 50%;
}

.header-home-link {
  width: 160px;
  padding-left: 5px;
}

.header-dark-mode {
  width: 160px;
  text-align: right;
}

.dark-mode-icon {
  padding-right: 5px;
}

.jiggle:hover {
  display: inline-block;
  animation: jiggle 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

@keyframes jiggle {
  10%,
  90% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  20%,
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  30%,
  50%,
  70% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  40%,
  60% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
}

div.top-left-horizontal-border {
  position: absolute;
  width: 20px;
  height: 1px;
  top: 0;
  left: 0;
  background-color: var(--green-text);
}

div.top-left-vertical-border {
  position: absolute;
  width: 1px;
  height: 20px;
  top: 0;
  left: 0;
  background-color: var(--green-text);
}

div.top-right-horizontal-border {
  position: absolute;
  width: 20px;
  height: 1px;
  top: 0;
  right: 0;
  background-color: var(--green-text);
}

div.top-right-vertical-border {
  position: absolute;
  width: 1px;
  height: 20px;
  top: 0;
  right: 0;
  background-color: var(--green-text);
}

div.bottom-right-vertical-border {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 20px;
  background-color: var(--green-text);
}

div.bottom-right-horizontal-border {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 1px;
  background-color: var(--green-text);
}

div.bottom-left-vertical-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 20px;
  background-color: var(--green-text);
}

article.articleLink:hover div.top-left-vertical-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.top-left-horizontal-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.top-right-vertical-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.top-right-horizontal-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.bottom-left-vertical-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.bottom-left-horizontal-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.bottom-right-vertical-border {
  background-color: var(--orange-text);
}

article.articleLink:hover div.bottom-right-horizontal-border {
  background-color: var(--orange-text);
}

div.bottom-left-horizontal-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 1px;
  background-color: var(--green-text);
}

article.articleLink {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

div.articleList {
  text-align: left;
}

div.articleTitle {
  padding: 0.5rem;
  text-align: center;
}

div.articleTitle > h2 {
  color: var(--green-text);
  margin: 0;
}

div.articleTitle > p {
  margin: 0.25rem 0 0 0;
}

.homeStubTitle {
  margin: 0;
}

div.blogPostSection {
  padding-left: 1em;
}
